// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/WorkflowSwitcher.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/WorkflowSwitcher.tsx");
  import.meta.hot.lastModified = "1728902927000";
}
// REMIX HMR END

import { cn, formatUrlParams } from '~/lib/utils';
import { Button } from '~/components/ui/button';
import { Command, CommandGroup, CommandItem, CommandList, CommandSeparator } from '~/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover';
import { Fragment, useMemo, useState } from 'react';
import { APP_PAGES_URL, SYSTEM_USER_TYPES } from '~/lib/constants';
import { Link, useParams } from '@remix-run/react';
import { ChevronDownIcon } from 'lucide-react';
export const WorkflowSwitcher = ({
  user
}) => {
  _s();
  const {
    entityId,
    workspaceId
  } = useParams();
  const [open, setOpen] = useState(false);
  const workflowsList = useMemo(() => {
    const workflows = [{
      label: 'Maturity Workflow',
      description: 'View Maturity Workflow',
      url: formatUrlParams(APP_PAGES_URL.WORKFLOWS.MATURITY, {
        entityId,
        workspaceId
      })
    }, {
      label: 'Materiality Workflow',
      description: 'View Materiality Workflow',
      url: formatUrlParams(APP_PAGES_URL.WORKFLOWS.MATERIALITY, {
        entityId,
        workspaceId
      })
    }, {
      label: 'KPI Workflow',
      description: 'View KPI Workflow',
      url: formatUrlParams(APP_PAGES_URL.WORKFLOWS.KPI, {
        entityId,
        workspaceId
      })
    }, {
      label: 'Report Workflow',
      description: 'View Report Workflow',
      url: formatUrlParams(APP_PAGES_URL.WORKFLOWS.REPORT, {
        entityId,
        workspaceId
      })
    }];
    return workflows;
  }, [entityId, workspaceId]);
  if (user?.type !== SYSTEM_USER_TYPES.FE_USER || !entityId || !workspaceId) {
    return <Fragment />;
  }
  return <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button className='ml-auto' variant={'outlinePrimary'}>
            Workflows
            <ChevronDownIcon className='ml-2 h-4 w-4 text-muted-foreground' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='p-0' align='end'>
          <Command>
            <CommandList>
              <CommandGroup>
                {workflowsList.map(entity => <CommandItem key={entity.url} className={cn('bg-white border-b border-b-primary-100 last-of-type:border-b-0')}>
                    <Link prefetch='intent' to={entity.url} onClick={() => setOpen(false)} className='flex flex-col items-start px-4 py-2 cursor-pointer'>
                      <p className='font-bold'>{entity.label}</p>
                      <p className='text-sm text-muted-foreground'>
                        {entity.description}
                      </p>
                    </Link>
                  </CommandItem>)}
              </CommandGroup>
            </CommandList>
            <CommandSeparator />
          </Command>
        </PopoverContent>
      </Popover>
    </div>;
};
_s(WorkflowSwitcher, "uJCdkZkpfq0L6tEvL0gGiplxwzY=", false, function () {
  return [useParams];
});
_c = WorkflowSwitcher;
var _c;
$RefreshReg$(_c, "WorkflowSwitcher");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;